
import {defineComponent, onMounted, ref} from 'vue'
import Chart from "@/views/chart/Chart.vue";
import {ChartCode, ChartType} from "@/core/config/Constant";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import BaseRadio from "@/components/base/form/BaseRadio.vue";

export default defineComponent({
  name: "Dashboard",
  components: {BaseRadio, Chart},
  setup() {
    const days = ref(30);
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams("Dashboard", [])
    })

    return {
      days,
      ChartCode,
      ChartType,
    }
  }
})
